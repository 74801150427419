import {
  NOTIFICATION_RELOAD,
  NOTIFICATION_GET_ALL,
  GET_EXCHANGE_RATE,
} from "../actions/type";

const initialState = {
  reload: false,
  notificationData: [],
  exchangeRate: "",
};

function notificationReducer(notification = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case NOTIFICATION_GET_ALL:
      notification.notificationData = payload;
      return notification;

    case GET_EXCHANGE_RATE:
      notification.exchangeRate = payload;
      return notification;

    case NOTIFICATION_RELOAD:
      return { ...notification, reload: !notification.reload };

    default:
      return notification;
  }
}

export default notificationReducer;
