import NotificationService from "../api/services/notification.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import { NOTIFICATION_GET_ALL, NOTIFICATION_RELOAD } from "src/actions/type";

export const createNotification = (data) => async (dispatch) => {
  try {
    const res = await NotificationService.create(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const notificationUserById = (data) => async (dispatch) => {
  try {
    const res = await NotificationService.notificationUerById(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getPersonalNotifications = () => async (dispatch) => {
  try {
    dispatch(reloadNotifications());
    const res = await NotificationService.getPersonalNotifications();
    dispatch({
      type: NOTIFICATION_GET_ALL,
      payload: res.data.data,
    });
    dispatch(reloadNotifications());
  } catch (err) {
    dispatch(reloadNotifications());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const markAllNotificationsAsRead = () => async (dispatch) => {
  try {
    dispatch(reloadNotifications());
    const res = await NotificationService.markAllNotificationsAsRead();
    dispatch(showSuccessSnackbar("Updated successfully"));
    dispatch(getPersonalNotifications());
    dispatch(reloadNotifications());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadNotifications());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const reloadNotifications = () => (dispatch) => {
  dispatch({
    type: NOTIFICATION_RELOAD,
  });
};
