import axios from "../axios";

class NotificationService {
  create(data) {
    return axios.post("/notification/notifyall", data);
  }

  notificationUerById(data) {
    return axios.post("/notification/notifygroup", data);
  }

  getPersonalNotifications() {
    return axios.get("/notification/getpersonalnotifications");
  }

  markAllNotificationsAsRead() {
    return axios.put("/notification/setallasread");
  }
}

export default new NotificationService();
