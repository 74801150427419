import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./components/DashboardLayout";
import MainLayout from "./components/MainLayout";
import Users from "src/pages/cross/Users";
import WelcomeMessage from "src/components/welcomePage";
import Login from "src/pages/Login";
import NotFound from "src/pages/NotFound";
import GiftItemCategory from "src/pages/gifts/category";
import ItemOptions from "src/pages/gifts/options";
import GiftItems from "src/pages/gifts/item";
import GiftItemsAddForm from "src/pages/gifts/item/from/addForm";
import GiftItemsUpdateForm from "src/pages/gifts/item/from/updateForm";
import Wrapping from "src/pages/gifts/wrapping";
import GiftOrders from "src/pages/gifts/orders/Index";
import ItemSupplier from "src/pages/gifts/supplier";
import GiftItemBrand from "src/pages/gifts/brand";
import RatingQuestions from "src/pages/cross/ratingQuestions/Index";
import UserAddresses from "src/pages/gifts/userAddresses";
import Favorite from "src/pages/gifts/favorite";
import Statistic from "src/pages/cross/Ticket/Statistics/Index";
import Tickets from "src/pages/cross/Ticket/Index";
import TicketDetails from "src/pages/cross/Ticket/ticketDetails";
import TicketCategory from "src/pages/cross/Ticket/category";
import Departments from "src/pages/cross/department/DepartmentsConfig";
import Employee from "src/pages/cross/department/Employee/Index";
import Permissions from "src/pages/cross/dynamicAuth";
import ProfileSettingsView from "src/pages/Profile";
import FAQ from "src/pages/shared/faq/Index";
import GiftCart from "src/pages/gifts/cart/index";
import OrderRating from "src/pages/shared/orderRating";
import UsersCart from "src/pages/gifts/usersCart";
import Orders from "src/pages/cross/orders/Index";
import Banner from "src/pages/cross/banner";
import Wallet from "src/pages/Wallet";
import ImageUploadCenter from "src/pages/cross/Configuration/ImageUploadCenter/Index";
import EPayments from "src/pages/cross/Configuration/EPayments/Index";
import ContactInfo from "src/pages/cross/Configuration/contactInfo/Index";
import GeneralSetting from "src/pages/cross/Configuration/Settings/Index";
import AddressesList from "src/components/pagesContent/addresses/addressesContent";
import Onboarding from "src/pages/cross/Configuration/onboarding/Index";
import ShippingsConfigs from "src/pages/cross/Configuration/shippingConfig";
import Reasons from "src/pages/cross/Configuration/reasons";
import MobileAppVersion from "src/pages/cross/Configuration/MobileAppVersion";
import ExpensesList from "src/components/pagesContent/expenses/ExpensesContent";
import ExpenseTypesList from "src/components/pagesContent/expensesType/ExpensesTypeContent";
import Events from "src/pages/gifts/events";
import Promos from "src/pages/cross/promos";
import PopUpPost from "src/pages/cross/popUpPost";
import GiftDashboard from "src/pages/gifts/dashboard";
import ReportNew from "src/pages/reportsNew";
import WalletReport from "src/pages/WalletReports";
import Collections from "src/pages/gifts/collections";
import Agents from "src/pages/cross/Configuration/agentCityConfig/Index";
import GoIp from "src/pages/shared/goip";

const token = JSON.parse(localStorage.getItem("decodedIems"));

const GiftsRoutes = () => {
  const route = [
    {
      path: "app",
      element: <DashboardLayout />,
      children: [
        { path: "welcome_message", element: <WelcomeMessage /> },
        { path: "*", element: <Navigate to="/404" /> },
        { path: "users", element: <Users /> },
        { path: "gift_dashboard", element: <GiftDashboard /> },
        { path: "wallet/:id", element: <Wallet /> },
        { path: "ticket/statistic", element: <Statistic /> },
        { path: "ticket/allTickets", element: <Tickets /> },
        { path: "ticket/TicketDetails/:id", element: <TicketDetails /> },
        { path: "ticket/ticketCategory", element: <TicketCategory /> },
        { path: "Department/DepartmentConfig", element: <Departments /> },
        { path: "Department/employee", element: <Employee /> },
        { path: "Dynamic_Auth", element: <Permissions /> },
        { path: "settings", element: <ProfileSettingsView /> },
        { path: "gifts/item_category", element: <GiftItemCategory /> },
        { path: "gifts/item_options", element: <ItemOptions /> },
        { path: "gifts/supplier", element: <ItemSupplier /> },
        { path: "gifts/brand", element: <GiftItemBrand /> },
        { path: "gifts/items", element: <GiftItems /> },
        { path: "gifts/items/add_new_item", element: <GiftItemsAddForm /> },
        {
          path: "gifts/items/update_item/:item_id",
          element: <GiftItemsUpdateForm />,
        },
        { path: "gifts/wrapping", element: <Wrapping /> },
        { path: "gifts/orders", element: <GiftOrders /> },
        { path: "gifts/order/:id", element: <GiftOrders orderDetails /> },
        { path: "gifts/collections", element: <Collections /> },
        { path: "order/:id", element: <Orders orderDetails /> },
        { path: "rating_questions", element: <RatingQuestions /> },
        { path: "user_addresses", element: <UserAddresses /> },
        { path: "events", element: <Events /> },
        { path: "favorite", element: <Favorite /> },
        { path: "faq", element: <FAQ /> },
        { path: "gift_cart/:id", element: <GiftCart /> },
        { path: "order_rating", element: <OrderRating /> },
        { path: "gifts/customers_cart", element: <UsersCart /> },
        { path: "promos", element: <Promos /> },
        { path: "banners", element: <Banner /> },
        { path: "pop_up_post", element: <PopUpPost /> },
        { path: "agent_city_config", element: <Agents /> },
        { path: "image_upload_center", element: <ImageUploadCenter /> },
        { path: "E-Payments", element: <EPayments /> },
        { path: "contact_info", element: <ContactInfo /> },
        { path: "General_Settings", element: <GeneralSetting /> },
        { path: "addresses", element: <AddressesList /> },
        { path: "onboarding", element: <Onboarding /> },
        { path: "shipping_config", element: <ShippingsConfigs /> },
        { path: "reasons", element: <Reasons /> },
        { path: "mobile_app_version", element: <MobileAppVersion /> },
        { path: "expenses", element: <ExpensesList /> },
        { path: "expense_types", element: <ExpenseTypesList /> },
        { path: "reports", element: <ReportNew /> },
        { path: "wallet_reports", element: <WalletReport /> },
        { path: "go_ip", element: <GoIp /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "login", element: <Login /> },
        // { path: 'register', element: <Register /> },
        { path: "404", element: <NotFound /> },
        {
          path: "/",
          element: token ? (
            <Navigate to="app/welcome_message" />
          ) : (
            <Navigate to="/login" />
          ),
        },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
  ];

  const content = useRoutes(route);

  return content;
};

export default GiftsRoutes;
